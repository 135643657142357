<template>
	<section>
		<b-modal id="topup-swc-modal" centered header-class="border-0 pt-4 pb-0" no-close-on-backdrop :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
      <div class="float-right mt-2">
        <button
          class="btn btn-close d-flex justify-content-center align-items-center close-image"
          @click="$emit('close-topup')"
        >
				  <b-icon icon="x"></b-icon>
			  </button>
      </div>
      <div class="container-fluid my-4 px-4">
				<span class="topup-title">Topup SWC</span>
        <div class="list-swc__container mt-4">
          <div v-for="( data, index) in listPackage" :key="index" class="card-swc cursor-pointer" :class="choosedIndex == data._id ? 'choosed-swc' : ''" @click="$emit('update:choosedIndex', data)">
						<div class="my-4">
							<div class="d-flex align-items-center justify-content-center">
								<img src="@/assets/images/icons/ic_coin_swc_2.png" alt="SWC" width="35px">
								<h4 class="mb-0 ml-1 text-white">{{ data.swc_quota }}</h4>
							</div>
							<p class="mb-0 text-center text-white mt-2">{{ formatPrice(data.price_idr) }}</p>
						</div>
					</div>
        </div>
        <div class="input-swc">
					<label for="swc" class="mb-0">
						<input type="text" class="form-control mt-3" v-model="inputSwc" placeholder="Enter SWC amount" @input="handleInputChange" @focus="$emit('swc-focus')" @click="$emit('swc-focus')" :style="inputSwc && !isEnough ? 'border-color: #DF2723' : ''" :disabled="loadSwc">
					</label>
					<p v-if="inputSwc && !isEnough" class="validator__input">Minimum purchase of 50 SWC</p>
				</div>
        <div class="text-white text-left mt-3 w-100">
					<p class="mb-1">Total</p>
					<b-skeleton v-if="loadSwc" width="40%"></b-skeleton>
					<h3 v-else class="text-truncate w-100">{{ formatPrice(totalPrice) }}</h3>
				</div>
				<button :disabled="statusDisable" class="btn btn-purple btn-md btn-block mt-3" @click="$emit('next')"><h5 class="my-2 text-white">Next</h5></button>
			</div>
    </b-modal>
  </section>
</template>

<script>
export default {
  data() {
    return {
      inputSwc : this.value
    }
  },

  props : {
    listPackage : {
      type: Array,
      default() {
        return []
      }
    },

    choosedIndex : {
      type: String,
      default: null,
    },

    totalPrice : {
      type: Number,
      default: 0
    },

    value: {
      type: Number,
      default:  null,
    },

    statusDisable: {
      type: Boolean,
      default: true
    },

    isEnough: {
      type: Boolean,
      default: true
    },

    loadSwc: {
      type: Boolean,
      default: true
    }
  },

  methods : {
    formatPrice(price) {
      // Function yang digunakan untuk memformat harga
      return `IDR ${price.toLocaleString()}`;
    },

    handleInputChange() {
      this.inputSwc = this.inputSwc.replace(/[^0-9]/g, '');
      this.$emit('inputChange', this.inputSwc);
    },
  },

  watch: {
    value: {
      handler(newValue) {
        this.inputSwc = newValue || null;
      },
      deep: true
    },
    // inputSwc: {
    //   handler(newValue) {
    //     console.log(newValue)
    //     this.$emit('input', newValue)
    //   },
    //   deep: true
    // }
  },
}
</script>

<style scoped>
.btn-close-modal {
	width: 22px;
	height: 22px;
	border-radius: 11px;
	color: black;
	background-color: white;
	font-size: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.topup-title {
	font-size: 30px;
	color: white;
	font-weight: 900;
}

.list-swc__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1rem;
}

.card-swc {
  border: 1px solid #585859;
  border-radius: 10px;
  display: grid;

  transition: border 0.25s, background-color 0.25s;
}

.input-swc label {
  position: relative;
  width: 100%;
}

.input-swc label::before {
  content: "";
  position: absolute;
  left: 0.5rem;
  top: 13px;
  bottom: 0;
  width: 40px;
  background: url("~@/assets/images/icons/ic_coin_swc_1.png") center / contain no-repeat;
  background-size: 20px;
  z-index: 10;
}

.input-swc label input {
  padding-left: 50px;
  height: 2.8rem;
	border-radius: 8px;
	box-shadow: none;
	transition: border 0.25s;
  background-color: transparent;
  color: white;
  border-color: #585859;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn-purple{
  color: white;
  min-height: 40px;
  background-color: #6D75F6;
  border: 1px solid #6D75F6
}

.choosed-swc {
  border-color : #6D75F6;
  background-color: #6D75F6;
}

.validator__input {
	font-size: 12px;
	margin-bottom:0;
	margin-top: 0.5rem;
	color: #DF2723;
	font-weight: 400;
}
</style>
<style>

@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#topup-swc-modal .modal-content {
  background-color: #222222;
  border-radius: 10px !important;
  font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mc__cancel-button, .mc__save-button {
  padding: 6px 20px;
  border-radius: 8px;
  color: #FFFFFF;
  transition: background-color 0.25s, color 0.25s;
}

.mc__cancel-button {
  background-color: #2D2D2D;
}

.mc__cancel-button:hover {
  background-color: #3D3D3D;
}

.mc__save-button {
  background-color: #6D75F6;
}

.mc__save-button:disabled {
  background-color: #2D2D2D;
  color: #585859;
}
button {
  box-shadow: none !important;
}
</style>